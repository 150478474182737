import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import Img from "gatsby-image"
import { useTranslation } from "react-i18next"
import Seo from "../components/seo/seo"

const ContactPage = ({ data }) => {

  const { t } = useTranslation();
  return (
  <Layout>
    <Seo
      title={t("contact.seoTitle")}
      description={t("contact.seoDescription")}
      seoImage={data.backgroundImage.childImageSharp.resize.src}
    />
    <div className="section is-medium is-gradient is-relative">
      <Img
        fluid={data.backgroundImage.childImageSharp.fluid}
        style={{
          position: "absolute",
          overflow: "hidden",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          opacity: 0.3,
        }}
        alt={t("contact.altTagMainImage")}
      />
      {/* <div
        style={{
          position: 'absolute',
          zIndex: 1,
          overflow: 'hidden',
          bottom: 0,
          top: 0,
          height: '100%',
          width: '100%',
          backgroundSize: 'cover',
        }}
      >
        <ContactUsSvg />
      </div> */}
      <div className="container" style={{ position: "relative", zIndex: 5 }}>
        <div className="columns">
          <div className="column is-1"></div>
          <div className="column is-4">
            <div className="content is-large">
              <h1 className="title is-1">{<>{t("contact.titolo")}</>}</h1>
            </div>
            <div className="content is-large" style={{ paddingBottom: "50px" }}>
              <h2 className="title is-4 is-white" style={{ lineHeight: 1.3 }}>
                {<>{t("contact.introduzione")}</>}
              </h2>
            </div>
          </div>
          <div className="column is-1"></div>
          <div
            className="column is-4 is-gradient-bottom-right"
            style={{ padding: "50px" }}
          >
            {/* Contact form */}

            <form
              name="contattoproduttivo"
              method="POST"
              netlify-honeypot="bot-field"
              data-netlify="true"
            >
              <input type="hidden" name="form-name" value="contattoproduttivo" />
              <input type="hidden" name="bot-field" />
              <div className="field">
                <label className="label">
                  {t("contact.formNomeCognome")}
                </label>
                <input className="input" type="text" name="fullname"></input>
              </div>
              <div className="field">
                <label className="label">
                  {t("contact.formRagioneSociale")}
                </label>
                <input className="input" type="text" name="companyname"></input>
              </div>
              <div className="field">
                <label className="label">{t("contact.formEmail")}</label>
                <input className="input" type="email" name="email"></input>
              </div>
              <div className="field">
                <label className="label">
                  {t("contact.formOggetto")}
                </label>
                <input className="input" type="text" name="subject"></input>
              </div>
              <div className="field">
                <label className="label">
                 {t("contact.formMessaggio")}
                </label>
                <div className="control">
                  <textarea className="textarea" name="message"></textarea>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label className="checkbox">
                    <input type="checkbox" name="privacy" />
                    {t("contact.formPrivacy")}
                  </label>
                </div>
              </div>
              <button
                type="submit"
                className="button is-dark is-medium is-fullwidth"
              >
               {t("contact.formInvia")}
              </button>
            </form>

            {/* End of contact form */}
          </div>
        </div>
      </div>
    </div>
  </Layout>
  )
}

export default ContactPage

export const contactQuery = graphql`
  query {
    backgroundImage: file(relativePath: { eq: "Customer_Service.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
  }
`
